<div
  class="box"
  appDragDrop
  (files)="fileDropped($event)"
  (click)="fileUpload.click()"
>
  <div class="box-input">
    <input
      class="box-file"
      type="file"
      id="file"
      #fileUpload
      accept="image/*"
      (change)="onFileSelected($event)"
    />
    <div class="icon-container">
      <mat-icon>upload</mat-icon>
    </div>
    <label for="file"
      > Click to upload or drag logo here</label
    >
  </div>
</div>
